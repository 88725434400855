<template>
  <div class="py-3">
    <label class="form-label label-input">{{ label }}</label>
    <select @change="$emit('enviaOpcao', opcao)" class="form-select input-select" :class="getClasses(size)"
      v-model="opcao">
      <option v-for="(item, index) in opcoes" :key="index" :value="item.opcao">
        {{ item.opcao }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "inputSelect",
  props: {
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "default",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    caminhoIMG: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: false,
    },
    opcoes: {
      type: Array,
      default: [],
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      opcao: this.opcoes[0].opcao,
    }
  },
  emits: [
    'enviaOpcao'
  ],
  methods: {
    getClasses(size) {
      return size ? `form-select-${size}` : null;
    },
    // getStatus: (error, success) => {
    //   let isValidValue;

    //   if (success) {
    //     isValidValue = "is-valid";
    //   } else if (error) {
    //     isValidValue = "is-invalid";
    //   } else {
    //     isValidValue = null;
    //   }

    //   return isValidValue;
    // },
    // handleFocusout(e) {
    //   this.$emit('aoValueInput', e.target.value)
    //   //console.log('focusout', e.target.value)
    // }
  },
};
</script>
