export default class Alerta {
  constructor(evento, tipo, periodo, zona, nivel, estagio) {
    this.evento = evento;
    this.tipo = tipo;
    this.periodo = periodo;
    this.zona = zona;
    this.nivel = nivel;
    this.estagio = estagio;
  }
}
