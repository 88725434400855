<template>
    <div class="container">
        <div class="row ">
            <div class="col"><input-select @change="$emit('mudaEvento', opcao)" @envia-opcao="getOpcao" label="Evento"
                    size="lg" :opcoes="getEventos" /></div>
            <div class="col"><input-select @change="$emit('mudaTipo', opcao)" @envia-opcao="getOpcao" label="Tipo" size="lg"
                    :opcoes="getTipos" /></div>
            <div class="col"><input-select @change="$emit('mudaPeriodo', opcao)" @envia-opcao="getOpcao" label="Período"
                    size="lg" :opcoes="getPeriodos" /></div>
            <div class="col"><input-select @change="$emit('mudaZona', opcao)" @envia-opcao="getOpcao" label="Zona" size="lg"
                    :opcoes="getZonas" /></div>
            <div class="col"><input-select @change="$emit('mudaSeveridade', opcao)" @envia-opcao="getOpcao"
                    label="Nível de Severidade" size="lg" :opcoes="getNiveis" :caminho-i-m-g=true /></div>
            <div class="col"><input-select @change="$emit('mudaEstagio', opcao)" @envia-opcao="getOpcao"
                    label="Mudança de Estágio" size="lg" :opcoes="getEstagios" /></div>
        </div>
        <!-- <div class="row row-cols-2">
            <div class="col"><input-datepicker label="Data inicial" /></div>
            <div class="col"><input-datepicker label="Data final" /></div>
            <div class="col"><input-timepicker label="Hora" /></div>
        </div> -->
    </div>
</template>

<script>
import InputSelect from "../Input/InputSelect.vue";
import InputDatepicker from "../Input/InputDatepicker.vue";
import InputTimepicker from "../Input/InputTimepicker.vue";
import { mapGetters } from "vuex";

export default {
    components: { InputSelect, InputDatepicker, InputTimepicker },
    name: 'CardOpcoesAlertas',
    emits: [
        'mudaEvento',
        'mudaTipo',
        'mudaPeriodo',
        'mudaZona',
        'mudaSeveridade',
        'mudaEstagio',
    ],
    data() {
        return {
            opcao: ''
        }
    },
    computed: {
        ...mapGetters([
            "getEventos",
            "getTipos",
            "getPeriodos",
            "getZonas",
            "getNiveis",
            "getEstagios",
        ])
    },
    methods: {
        getOpcao(opcao) {
            this.opcao = opcao;
            //console.log(this.opcao);
        },
    }
}
</script>