import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index.js";
import axios from "axios";
import VueAxios from "vue-axios";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "./assets/css/sideNav.css";
import "./assets/css/main.css";
import "./assets/css/modal.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import { vueKeycloak } from '@baloise/vue-keycloak'


const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(store);
appInstance.use(VueAxios, axios);

appInstance.use(vueKeycloak, {
  initOptions: {
    flow: 'standard', // default
    checkLoginIframe: false, // default
    onLoad: 'login-required', // default
  },
  config: {
    url: 'https://auth.s1.segmenta.app/',
    realm: 'mesquita',
    clientId: 'mesquita'
  }
})

appInstance.mount("#app");
