<template>
    <div class="container my-5 conteudo-card-mensagem">
        <div class="card-cabecalho mt-5">
            PREVIEW DA MENSAGEM POR WPP
        </div>
        <div class="card card-mensagem">
            <div class="card-body">
                <div v-if="getIsEstagio">
                    <div class="sub-title-mensagem text-uppercase">Defesa Civil Alerta:</div>
                    <div class="text-card-mensagem">
                        <p>MUDANÇA para estágio de {{ estagio }}.</p>
                        <p>Devido a(o) {{ evento }} com intensidade {{ tipo }} no município, durante {{ periodo }}.</p>
                    </div>
                </div>
                <div v-else>
                    <div class="sub-title-mensagem ">
                        <p class="text-capitalize">WhatsApp</p>
                        <p class="text-uppercase">fra_01</p>
                        <p class="text-capitalize">Titulo: Defesa civil de mesquita - RJ</p>
                        <p class="text-uppercase">Alerta</p>
                    </div>
                    <p class="text-card-mensagem">
                        Alerta de {{ evento }} com intensidade {{ tipo }} no município,
                        durante {{ periodo }}.
                        Em caso de emergência, ligue (21)4042-1772.
                    </p>
                </div>

                <div class="mx-2 mt-4 py-2">
                    <img :src="`${severidade}`" class="card-img-bottom img-card-wpp" alt="...">
                </div>
                <div>
                    <p class="card-text rodape-card-wpp">Você recebeu esta mensagem, pois tem este local ativo em seus
                        alertas
                        da
                        Defesa Cívil.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'CardPreviewWPP',
    props: {
        evento: {
            type: String,
            default: ''
        },
        tipo: {
            type: String,
            default: ''
        },
        periodo: {
            type: String,
            default: ''
        },
        severidade: {
            type: String,
            default: ''
        },
        estagio: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters(["getIsEstagio"])
    }
}
</script>