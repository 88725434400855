<template>
    <div class="py-3">
        <label for="date" class="form-label label-input">{{ label }}</label>
        <div class="input-group date" id="datepicker">
            <input type="date" class="form-control input-datepicker" id="date" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputDatepicker',
    props: {
        label: {
            type: String,
            default: "",
        },
    }
}
</script>