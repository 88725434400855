<template>
    <div class="container conteudo-card-mensagem">
        <div class="card-cabecalho">
            PREVIEW DA MENSAGEM POR SMS
        </div>
        <div class="card card-mensagem">
            <div class="card-body">
                <div v-if="getIsEstagio">
                    <div class="text-card-mensagem">
                        <p>Defesa Civil RJ: Mudanca p/ estagio de {{ estagio }} devido {{ evento }} com intensidade
                            {{ tipo }} no municipio, durante {{ periodo }}. Em caso de emergencia, ligue 40421772.</p>

                    </div>
                </div>
                <div v-else>
                    <p class="text-card-mensagem">
                        Defesa Civil de Mesquita - RJ: Alerta de {{ evento }}
                        com intensidade {{ tipo }} no municipio,
                        durante {{ periodo }}.
                        Em caso de emergencia, ligue (21)4042-1772..
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'CardPreviewSMS',
    props: {
        evento: {
            type: String,
            default: ''
        },
        tipo: {
            type: String,
            default: ''
        },
        periodo: {
            type: String,
            default: ''
        },
        estagio: {
            type: String,
            default: ''
        },
    },
    computed: {
        ...mapGetters(["getIsEstagio"])
    }
}
</script>