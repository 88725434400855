

<template>
  <nav class="navbar d-flex justify-content-end px-5">
    <ul class="nav  align-items-center px-2">
      <li class="nav-item">
        <a class="nav-link" href="#"><img class="icon-navbar" src="../../assets/img/icons/icon-lupa.png" /></a>
      </li>
      <li class="nav-item">
        <div class="dropdown">
           <a class="nav-link user-login" href="#">
              <p>Usuário</p>
              <img class="icon-navbar icon-user-branco" src="../../assets/img/icons/icon-user-branco.png" />
          </a>
          <div class="dropdown-content">
            <a class="nav-link user-logout" @click="logout">
              <p>SAIR</p>
              <img class="icon-navbar icon-logout-branco" src="../../assets/img/icons/icon-logout.png" />
            </a>
          </div>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#"><img class="icon-navbar" src="../../assets/img/icons/icon-notification.png" /></a>
      </li>
    </ul>
  </nav>
</template>
<script>

import { useKeycloak } from '@baloise/vue-keycloak'

export default {
  name: "Navbar",
  methods: {
    logout: function(){
              const  {
              keycloak
              } = useKeycloak()
              keycloak.logout({
                redirectUri: window._env_.VUE_APP_REDIRECT_URI || process.env.VUE_APP_REDIRECT_URI
              })
            }
  }
};
</script>
