<template>
    <!-- <div class="container px-2"> -->
    <div class="row px-2">
        <div class="col-6 px-3">
            <button type="button" class="btn-cancelar" @click="$emit('clickCancel')">
                <span><img src="../../assets/img/icons/icon-x-circle.png"></span>
                <span>Cancelar</span>
            </button>
        </div>
        <div class="col-6 px-3">
            <button type="button" class="btn-enviar" @click="$emit('clickAccept')">
                <span><img src="../../assets/img/icons/icon-check-circle.png"></span>
                <span>Enviar</span>
            </button>
        </div>
    </div>

    <!-- </div> -->
</template>

<script>
export default {
    name: 'Btn-group',
    emits: ['clickAccept', 'clickCancel']
}
</script>