<template>
    <div class="py-3">
        <label for="date" class="form-label label-input">{{ label }}</label>
        <div class="input-group time" id="timepicker">
            <input type="time" class="form-control input-timepicker" id="time" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputTimepicker',
    props: {
        label: {
            type: String,
            default: "",
        },
    }
}
</script>