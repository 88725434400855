import zenviaAPI from "@/services/api-zenvia";

const moduloDisparaAlertas = {
  state: {
    eventos: [
      { opcao: "Estiagem" },
      { opcao: "Chuvas" },
      { opcao: "Vendaval" },
      { opcao: "Granizo" },
    ],
    tipos: [
      { opcao: "Fraca" },
      { opcao: "Fraca-Moderada" },
      { opcao: "Moderada" },
      { opcao: "Moderada-Forte" },
      { opcao: "Forte" },
    ],
    periodos: [
      { opcao: "a Manhã" },
      { opcao: "a Manhã-Tarde" },
      { opcao: "a Tarde" },
      { opcao: "a Tarde-Noite" },
      { opcao: "a Noite" },
      { opcao: "todo o dia" },
      { opcao: "as próximas horas" },
    ],
    zonas: [{ opcao: "Municipio" }],
    niveis_severidade: [
      { opcao: "1", caminhoIMG: "../../../img/estagio_de_Vigilancia.jpg" },
      { opcao: "2", caminhoIMG: "../../../img/estagio_de_Atencao.jpg" },
      { opcao: "3", caminhoIMG: "../../../img/estagio_de_Alerta.jpg" },
      { opcao: "4", caminhoIMG: "../../../img/estagio_de_Alerta_Maximo.jpg" },
    ],
    mudanca_estagio: [
      { opcao: "" },
      { opcao: "Vigilância" },
      { opcao: "Atenção" },
      { opcao: "Alerta" },
      { opcao: "Alerta Máximo" },
    ],
    mensagem: "",
    isEstagio: false,
  },
  getters: {
    getEventos: (state) => state.eventos,
    getTipos: (state) => state.tipos,
    getPeriodos: (state) => state.periodos,
    getZonas: (state) => state.zonas,
    getNiveis: (state) => state.niveis_severidade,
    getEstagios: (state) => state.mudanca_estagio,
    getMensagem: (state) => state.mensagem,
    getIsEstagio: (state) => state.isEstagio,
  },
  actions: {
    sendAlertas({ commit }, msgAlerta) {
      commit("sendAlerta", msgAlerta);
    },
    setEstagioChange({ commit }, estagio) {
      commit("estagioChange", estagio);
    },
  },
  mutations: {
    sendAlerta: (state, msgAlerta) => {
      console.log("Enviando o Alerta", msgAlerta);
      zenviaAPI
        .post("/contact/getForAlert", msgAlerta)
        .then((resp) => {
          console.log("Alerta enviado com sucesso", resp);
          return (state.mensagem = "Alerta enviado com sucesso");
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              (state.mensagem =
                "A requisição foi feita e o servidor respondeu com um código de status que sai do alcance de 2xx")
            );
            console.error("Payload: ", error.response.data);
            console.error("Status: ", error.response.status);
            console.error("Headers: ", error.response.headers);
            return state.mensagem;
          } else if (error.request) {
            // `error.request` é uma instância do XMLHttpRequest no navegador e uma instância de
            // http.ClientRequest no node.js
            console.error(
              (state.mensagem =
                "A requisição foi feita mas nenhuma resposta foi recebida :"),
              error.request
            );
            return state.mensagem;
          } else {
            // Alguma coisa acontenceu ao configurar a requisição que acionou este erro.
            console.error("Error", error.message);
            state.mensagem = "Erro durante o disparo de alerta!";
            return state.mensagem;
          }
        });
    },
    estagioChange(state, estagio) {
      if (estagio === "") {
        state.isEstagio = false;
      } else {
        state.isEstagio = true;
      }
    },
  },
};

export default moduloDisparaAlertas;
