import axios from "axios";
const zenviaAPI = axios.create({
  baseURL: window._env_.VUE_APP_ZENVIA_URL_BASE || process.env.VUE_APP_ZENVIA_URL_BASE,
  headers: {
    "x-api-key": window._env_.VUE_APP_X_API_KEY || process.env.VUE_APP_X_API_KEY,
    "Access-Control-Allow-Origin": "*",
  },
});

export default zenviaAPI;
