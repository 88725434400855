<template>
  <sidenav class="side-nav" />
  <!-- <div class="container-fluid position-absolute px-0"> -->

  <main class="conteudo-main">
    <navbar />
    <router-view />
  </main>
  <!-- </div> -->
</template>
<script>
import Sidenav from "./components/Sidenav";
import Navbar from "./components/Navbars/Navbar.vue";

export default {
  name: "App",
  components: {
    Sidenav,
    Navbar,
  },
};
</script>
