<template>
  <div class="container-fluid py-4 px-5">
    <!-- Modal -->
    <ModalOK :text="getMensagem" />
    <!-- Fim modal -->
    <div class="title-page">
      <span><img class="icone-title" src="../assets/img/icons/icon-alert.png" /></span>
      <p class="txt-title-page">DISPARAR ALERTA(S)</p>
    </div>

    <div class="row">
      <div class="col-lg-6 col-opcoes">
        <div class="px-0 py-0">
          <card-opcoes-alertas @muda-evento="setChangeEvento" @muda-tipo="setChangeTipo" @muda-periodo="setChangePeriodo"
            @muda-zona="setChangeZona" @muda-severidade="setChangeSeveridade" @muda-estagio="setChangeEstagio" />
        </div>
        <div id="group-btn-dispara-alerta" class="position-absolute bottom-0 w-100 d-flex align-content-end my-5">
          <btn-group @click-accept="disparaAlerta" />
        </div>
      </div>
      <div class="col-lg-6 cols-cards">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <card-preview-s-m-s :evento="evento ? evento : getEventos[0].opcao" :tipo="tipo ? tipo : getTipos[0].opcao"
              :periodo="periodo ? periodo : getPeriodos[0].opcao" :estagio="estagio ? estagio : getEstagios[0].opcao" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
            <card-preview-w-p-p :evento="evento ? evento : getEventos[0].opcao" :tipo="tipo ? tipo : getTipos[0].opcao"
              :periodo="periodo ? periodo : getPeriodos[0].opcao"
              :severidade="nivel ? nivel.caminhoIMG : getNiveis[0].caminhoIMG"
              :estagio="estagio ? estagio : getEstagios[0].opcao" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardOpcoesAlertas from "../components/cards/CardOpcoesAlertas.vue";
import CardPreviewSMS from "@/components/cards/CardPreviewSMS.vue";
import CardPreviewWPP from "@/components/cards/CardPreviewWPP.vue";
import BtnGroup from "@/components/btn/BtnGroup.vue";
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min";
import Alerta from "@/models/Alerta.js";
import { mapGetters, mapActions } from "vuex";
import ModalOK from "@/components/modal/modalOK.vue";


export default {
  components: { CardOpcoesAlertas, CardPreviewSMS, CardPreviewWPP, BtnGroup, ModalOK },
  name: "DisparoAlertas",
  data() {
    return {
      evento: '',
      tipo: '',
      periodo: '',
      zona: '',
      nivel: '',
      estagio: '',
    }
  },
  computed: {
    ...mapGetters([
      "getNiveis",
      "getEventos",
      "getTipos",
      "getPeriodos",
      "getZonas",
      "getEstagios",
      "getMensagem",
      "getIsEstagio"
    ]),
  },
  methods: {
    ...mapActions(["sendAlertas", "setEstagioChange"]),

    setChangeEvento(selecionado) {
      this.evento = selecionado;
    },
    setChangeTipo(selecionado) {
      this.tipo = selecionado;
    },
    setChangePeriodo(selecionado) {
      this.periodo = selecionado;
    },
    setChangeZona(selecionado) {
      this.zona = selecionado;
    },
    setChangeSeveridade(selecionado) {
      this.nivel = this.getNiveis.find(({ opcao }) => opcao === selecionado);
      return this.nivel;
    },
    setChangeEstagio(selecionado) {
      this.setEstagioChange(selecionado);
      this.estagio = selecionado;
    },
    async disparaAlerta() {
      console.log("criando alerta");
      if (this.evento === '') {
        this.evento = this.getEventos[0].opcao;
      }
      if (this.tipo === '') {
        this.tipo = this.getTipos[0].opcao;
      }
      if (this.periodo === '') {
        this.periodo = this.getPeriodos[0].opcao;
      }
      if (this.zona === '') {
        this.zona = this.getZonas[0].opcao;
      }
      if (this.nivel === '') {
        this.nivel = this.getNiveis[0];
      }
      if (this.estagio === '') {
        this.estagio = this.getEstagios[0].opcao;
      }
      let msgAlerta = new Alerta(this.evento, this.tipo, this.periodo, this.zona, this.nivel.opcao, this.estagio);
      console.log(msgAlerta);
      await this.sendAlertas(msgAlerta).then(this.showModal());
    },
    showModal() {
      const myModal = new bootstrapBundleMin.Modal('#modalOk', { keyboard: true });
      myModal.show();
    },
  }
}
</script>